<template>
  <div class="grey lighten-3 customer-app">
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-card class="mx-auto" rounded="lg" elevation="5" max-width="1608">
            <v-card-title
              >Applications <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-card-title>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="customers"
                :search="search"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.percentage`]="{ item }">
                  <v-progress-linear :value="item.percentage" height="25">
                    <template>
                      <strong>{{ Math.ceil(item.percentage) }}%</strong>
                    </template>
                  </v-progress-linear>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="viewItem(item)">
                    mdi-eye
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { eventBus } from "../../main";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  name: "CreditApplicationsTable",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "customer_name",
        },
        { text: "Last name", value: "customer_last_name" },
        { text: "Email", value: "email" },
        { text: "Company Name", value: "company_name" },
        { text: "Created", value: "created" },
        { text: "Updated", value: "updated" },
        { text: "Status", align: "center", value: "status" },
        { text: "Percentage", align: "center", value: "percentage" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      customers: [],
      editedIndex: -1,
      editedItem: {
        PartitionKey: "",
        RowKey: "",
        company_name: "",
        customer_last_name: "",
        customer_name: "",
        email: "",
        form_data: {},
        percentage: 0,
        status: "",
      },
    };
  },
  methods: {
    getColor(status) {
      switch (status) {
        case "NEW":
          return "red";
        case "IN PROGRESS":
          return "orange";
        case "READY TO SIGN":
          return "light-green ";
        case "SIGN REQUESTED":
          return "green ";
        case "FINISHED":
          return "green";
        default:
          return "gray";
      }
    },
    getAllCustomers() {
      axios
        .get(process.env.VUE_APP_GET_ALL_CUSTOMERS)
        .then((response) => {
          // console.log(response);
          this.customers = response.data.customers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewItem(item) {
      eventBus.$emit("showCustomerApplicationViewer", item);
    },
    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      eventBus.$emit("showDeleteDialog", this.editedItem);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  created() {
    this.getAllCustomers();
  },
  updated() {
    eventBus.$on("updateCustomerTable", this.getAllCustomers);
  },
};
</script>

<style scoped>
.customer-app {
  width: 100%;
  height: 100%;
}
</style>
